<div class="bannerContainer">
    <div class="sliderContainer">
        <div class="sliderContent">
            {{ string1 }}
        </div>
        <div class="sliderContent">
            {{ string2 }}
        </div>
        <div class="sliderContent">
            {{ string3 }}
        </div>
    </div>
</div>
