import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: `./dropdown.component.html`,
  styleUrls: ['./dropdown.component.scss'],
})

export class DropdownComponent{
  @Input('selected') selectedItem: any;
  @Input('data') data: Array<any>;
  @Input('defaultTitle') defaultTitle: string = 'Choose an option';
  @Input('property') property?: string;    // Name of the key to be used to identify the attribute using which the item's text should be displayed.
  //@Input('showCheckBox') showCheckBox: boolean;

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  public opened = false;

  ngOnInit(){
    this.selectedItem = this.data?.find((item: any) => item.selected == true);
    
    // None selected = set default, if available:
    if(!this.selectedItem){
      this.selectedItem = this.data?.find((item: any) => item.default == true);
    }
  }

  selectItem(item: any): void {
    this.opened = false;
    if(item.default) item = null;
    this.onClick.emit(item);
  }

  close(){
    this.opened = false;
  }

  toggle(){
    this.opened = !this.opened;
  }


}