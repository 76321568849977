import { Component } from "@angular/core";
import { NavigationService } from "@services/navigation.service";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"],
})
export class HeaderComponent {
	public showNavigation: boolean = null;

	constructor(private navigationService: NavigationService) {}

	ngOnInit() {
		this.navigationService.showNavigation.subscribe((showNavigation) => {
			this.showNavigation = showNavigation;
		})
	}

	toggleNavigation() {
		this.showNavigation ? this.navigationService.navigationHide() : this.navigationService.navigationShow();
	}
}
