import { Component } from "@angular/core";

@Component({
	selector: "app-captcha",
	templateUrl: "./captcha.component.html",
	styleUrls: ["./captcha.component.scss"],
})
export class CaptchaComponent {
	private captcha: HTMLElement;

  ngOnInit() {
    // this.generate();
  }

	generate() {
	// 	(<HTMLInputElement>document.getElementById("submit")).value = "";

	// 	this.captcha = document.getElementById("image");
	// 	let uniquechar = "";

	// 	const randomchar =
	// 		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

	// 	for (let i = 1; i < 5; i++) {
	// 		uniquechar += randomchar.charAt(Math.random() * randomchar.length);
	// 	}

	// 	this.captcha.innerHTML = uniquechar;
	}

	printmsg() {
	// 	const usr_input = (<HTMLInputElement>document.getElementById("submit")).value;

	// 	if (usr_input == this.captcha.innerHTML) {
	// 		let s = (document.getElementById("key").innerHTML = "Matched");
	// 		this.generate();
	// 	} else {
	// 		let s = (document.getElementById("key").innerHTML = "not Matched");
	// 		this.generate();
	// 	}
	}
}
