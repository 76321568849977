<div class="">
  <a id="top_anchor"></a>
  <app-banner></app-banner>
  <div class="bannerImg">
    
  </div>
  <div class="introContainer">
    <div class="introContent">
      <p class="introTitle" [innerHTML]="introTitle">

      </p>
      <p class="introText" [innerHTML]="introText">
      </p>
      <div class="introButton">
        <a class="button" href="#contact_anchor">CONTACT</a>
      </div>
    </div>
  </div>
  <app-text-carousel></app-text-carousel>
  <app-form></app-form>
  <app-contact></app-contact>
  <app-cookie></app-cookie>
</div>
