import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: []
})
export class CheckboxComponent {
  @Input() public name: string;
  @Input() public checked: boolean = false;
  @Input() public disabled: boolean = false;
  
  @Output() public onSelect = new EventEmitter(false);

  constructor() { }

  clickCheckbox(){
    this.onSelect.emit();
  }
}
