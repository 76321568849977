import { Component, Input } from "@angular/core";
import { text } from "../../../assets/text.js";
import { NavigationService } from "../../services/navigation.service";


@Component({
	selector: "app-navigation",
	templateUrl: "./navigation.component.html",
	styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent {
	public anchors: string[];
	public currentAnchor: string = "home";
	public showNavigation: boolean = null;

	constructor(private navigationService: NavigationService) {
		this.anchors = text.navigation;
	}

	ngOnInit() {
		this.navigationService.showNavigation.subscribe((showNavigation) => {
			console.log(showNavigation);
			this.showNavigation = showNavigation;
		})
	}

	hideNavigation() {
		if (this.showNavigation) {
			this.navigationService.navigationHide();
		}
	}

	changeAnchor(anchor: string) {
		this.currentAnchor = anchor;
	}
}
