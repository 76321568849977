<div onload="generate()">
	<div id="user-input" class="inline">
		<input type="text" id="submit" placeholder="Captcha code" />
	</div>

	<div class="inline" (click)="generate()">
		<i class="fas fa-sync"></i>
	</div>

	<div id="image" class="inline" selectable="False"></div>
	<input type="submit" id="btn" (click)="printmsg()" />

	<p id="key"></p>
</div>
