import { Component,  Input } from '@angular/core';

/* USAGE
<app-spinner #mySpinner [transparent]="true" [absolute]="true"></app-spinner>

# Optional: Calling spinner method from .ts:
@ViewChild('mySpinner', {static: false}) private spinner:ModalComponent;
spinner.show();


# Optional: calling spinner method from html:
mySpinner.show();

*/

@Component({
	selector: 'app-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss']
})

export class SpinnerComponent{
  @Input() public class:string;   // absolute, transparent, inline, big
}
