<div class="application">
	<div class="wrapper">
		<ng-container *ngIf="initialized || isSettings">
			<app-header></app-header>
			<div class="views">
				<router-outlet></router-outlet>
			</div>
		</ng-container>
	</div>
</div>
