<div class="headerContainer">
    <div class="logoContainer" (click)="toggleNavigation()">
        <div class="logoView">
            <img src="assets/images/loosvelt_logo.png" alt="logo_bart_loosvelt">
        </div>
        <div class="navigationView">
            <!-- <img src="assets/images/menu.png" alt="menu_icon"> -->
        </div>
    </div>
</div>
