import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from '@views/home/home.component';

// debug only:
import { FormsComponent } from '@views/debug/forms/forms.component';


const routes: Routes = [
	{ path: '', component: HomeComponent, },

	// debug only:
	{ path: 'debug/forms', component: FormsComponent },

	// 404:
	{ path: '**', redirectTo: '/home' },

];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		//scrollPositionRestoration: 'enabled'		// auto-scroll to top on route change
	})],
	exports: [RouterModule]
})

export class AppRoutingModule { }
