import { Component, ViewEncapsulation } from "@angular/core";
import { text } from "../../../assets/text.js";

@Component({
	selector: "app-text-carousel",
	templateUrl: "./text-carousel.component.html",
	styleUrls: ["./text-carousel.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class TextCarouselComponent {
	public items: string[] = Object.keys(text.carousel);
	public texts: {} = text.carousel;
	public itemSelected: string = this.items[0];

	constructor() {

	}

	ngOnInit(): void {

	}

	selectItem(item: string): void {
		this.itemSelected = item;
	}
}
