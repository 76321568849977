<div class="custom-dropdown" [class.opened]="opened" [class.no-selection]="selectedItem==null || selectedItem?.default">
	<div class="menu" (click)="toggle()" (clickOutside)="close()">
	  <span [innerText]="selectedItem ? (property ? selectedItem[property] : selectedItem) : defaultTitle"></span>
	  <div class="arrow"></div>
	</div>

	<ul class="items" *ngIf="opened">
	  <li *ngFor="let item of data" (click)="selectItem(item)" [class.selected]="item.selected">
		 <!--<span class="fa" [style.display]="showCheckBox ? 'inline-block' : 'none'" [class.fa-check]="item.selected"></span>-->
		 <span [innerText]="property ? item[property] : item"></span>
		</li>

	  <li *ngIf="data?.length == 0" class="empty">No data to display</li>
	</ul>
 
	<div *ngIf="opened" class="top-bar-overlay" (click)="close()"></div>
 </div>
