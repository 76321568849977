import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

/**
 * Service to show or hide the navigation.
 */
@Injectable({
	providedIn: "root",
})
export class NavigationService {
	public showNavigation = new BehaviorSubject<boolean>(false);

	constructor(){}
    
    navigationShow() {
        this.showNavigation.next(true);
    }

    navigationHide() {
        this.showNavigation.next(false);
    }
}