import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner2',
  templateUrl: './spinner2.component.html',
  styleUrls: ['./spinner2.component.scss']
})
export class Spinner2Component {
  @Input() public class:string;   // absolute, transparent, inline, big

  constructor() { }

}
