<div class="contactContainer">
	<div class="contactContent">
		<div class="contactLine"></div>
		<div class="contactTitle">CONTACT</div>
		<div class="contactSubTitle">RAADPLEGING OP AFSPRAAK</div>
		<div class="contactGrid">
			<p
				*ngFor="let item of textFields"
				[innerHTML]="parser(item)"
				(click)="showPolicy(item)"
			></p>
			<a class="upButton" href="#top_anchor">
				<img src="assets/images/arrow.png"/>
			</a>
		</div>
	</div>
</div>

<div class="overlay">
	<div id="disclaimer">
		<div class="center">
			<button class="policyClose top" (click)="hidePolicy('disclaimer')">
				Sluiten
			</button>
		</div>
		<p class="bigger"><b>DISCLAIMER</b></p>
		<p>
			Het gebruik van onze website moet steeds gebeuren conform de rechten en
			plichten die duidelijk op de website vermeld staan en de rechten en
			plichten die bepaald zijn in de Disclaimer, de Verkoopsvoorwaarden en de
			Privacy Verklaring. Het geheel van deze teksten zijn onze Algemene
			Voorwaarden.
		</p>
		<p>
			Deze Algemene Voorwaarden gelden zowel voor ons, Bart Loosvelt, als voor
			jou, de Gebruiker. Zodra je gebruik maakt van onze website erken en
			aanvaard je uitdrukkelijk dat onze Algemene Voorwaarden van toepassing
			zijn en dat volledig verzaakt wordt aan de toepassing van eigen Algemene
			Voorwaarden.
		</p>
		<p>
			Wij kunnen in uitzonderlijke gevallen afwijken van de Algemene
			Voorwaarden, voor zover deze afwijkingen schriftelijk worden vastgelegd en
			aanvaard worden door alle partijen. Deze afwijkingen gelden slechts ter
			vervanging of aanvulling van de clausules waar ze betrekking op hebben en
			hebben geen effect op de toepassing van overige bepalingen uit de Algemene
			Voorwaarden.
		</p>
		<p><strong>1. Wie zijn wij? </strong></p>
		<p>
			De website
			<a href="https://advocaatloosvelt.be">advocaatloosvelt.be</a> is
			een initiatief van:
		</p>
		<p>
			Bart Loosvelt<br />
			Zwedenlaan 9, 8550 Zwevegem<br />
			<a href="mailto:info@advocaatloosvelt.be">info&#64;advocaatloosvelt.be</a
			><br />
			Telefoon: 0486 86 17 81
		</p>
		<p>
			Contacteer ons gerust indien u verdere vragen of opmerkingen heeft; wij
			beloven u een spoedig antwoord!
		</p>
		<p><strong>2. Onze website</strong></p>
		<p><u>2.1 Goede werking, veiligheid en toegankelijkheid </u></p>
		<p>
			Je kan ervan op aan, wij bieden een gebruiksvriendelijke website aan die
			veilig is voor iedere Gebruiker. We nemen dan ook alle redelijke
			maatregelen die nodig zijn om de goede werking, veiligheid en
			toegankelijkheid van onze website te garanderen. Toch kunnen we jou geen
			absolute garanties geven en moet men onze maatregelen beschouwen als een
			middelenverbintenis.
		</p>
		<p>
			Ieder gebruik van de website gebeurt steeds op eigen risico. Dit betekent
			dat wij geen aansprakelijkheid dragen voor schade die voortvloeit uit
			storingen, onderbrekingen, schadelijke elementen of defecten aan de
			website, ongeacht het bestaan van een vreemde oorzaak of overmacht.
		</p>
		<p>
			We hebben het recht om de toegang tot onze website te allen tijde te
			beperken en/of geheel of gedeeltelijk te onderbreken, zonder voorafgaande
			waarschuwing. We doen dit in principe uitsluitend indien de omstandigheden
			dit verantwoorden, maar dit is geen absolute voorwaarde.
		</p>
		<p><u>2.2 Inhoud op onze website</u></p>
		<p>
			De inhoud van de website wordt grotendeels door ons bepaald en wij
			besteden de grootste zorg aan deze inhoud. Dit wil zeggen dat we de nodige
			maatregelen nemen om onze website zo volledig, nauwkeurig en actueel
			mogelijk te houden, ook wanneer content wordt aangeleverd door derde
			partijen. De inhoud op onze website kan steeds gewijzigd, aangevuld of
			verwijderd worden.
		</p>
		<p>
			Toch kunnen we geen garanties geven omtrent de kwaliteit van de informatie
			op onze website. Het is mogelijk dat informatie niet volledig, voldoende
			accuraat en/of nuttig is. We zijn bijgevolg niet aansprakelijk voor
			(directe en indirecte) schade die de Gebruiker lijdt ten gevolge van de
			informatie op onze website.
		</p>
		<p>
			In het geval bepaalde inhoud op onze website een schending van de geldende
			wetgeving en/of een schending van de rechten van derde partijen betekent
			en/of eenvoudigweg niet door de beugel kan, vragen wij aan jou om ons dit
			zo spoedig mogelijk te melden zodat we de gepaste maatregelen kunnen
			nemen. Zo kunnen we overgaan tot een gedeeltelijke of gehele verwijdering
			en/of aanpassing van de inhoud.
		</p>
		<p>
			Onze website bevat inhoud die gedownload kan worden. Iedere download van
			onze website gebeurt steeds op eigen risico. Hiervoor zijn wij niet
			aansprakelijk en schade ten gevolge van een verlies van data of schade aan
			het computersysteem valt volledig en uitsluitend onder de
			verantwoordelijkheid van Gebruiker.
		</p>
		<p>
			Specifiek voor prijzen en andere informatie over producten op de website
			geldt een voorbehoud van kennelijke programmeer- en typefouten. De
			Gebruiker kan op basis van dergelijke fouten geen overeenkomst claimen met
			Bart Loosvelt.
		</p>
		<p><u>2.3 Wat wij van jou als Gebruiker verwachten</u></p>
		<p>
			Ook de Gebruiker draagt een zekere verantwoordelijkheid bij het gebruiken
			van onze website. De Gebruiker moet zich steeds onthouden van handelingen
			die een schadelijke impact kunnen hebben op de goede werking en veiligheid
			van de website. Zo mag de website niet gebruikt worden om ons business
			model te omzeilen en/of om informatie van andere Gebruikers op grote
			schaal te verzamelen.
		</p>
		<p>
			Het is bijgevolg niet toegelaten om onze website te gebruiken voor de
			verspreiding van inhoud die schade aan andere Gebruikers van de website
			kan toebrengen, zoals de verspreiding van schadelijke programmatuur zoals
			computervirussen, malware, worms, trojans en cancelbots. De verspreiding
			van ongevraagde en/of commerciële berichten via de website, zoals junk
			mail, spamming en kettingbrieven, valt hier ook onder.
		</p>
		<p>
			Wij behouden ons het recht voor om alle noodzakelijke handelingen te
			treffen die herstel kunnen opleveren voor ons en voor onze Gebruikers,
			zowel op gerechtelijk als buitengerechtelijk vlak. De Gebruiker is als
			enige persoonlijk en integraal verantwoordelijk indien zijn handelingen en
			gedragingen effectief schade veroorzaken aan de website en de andere
			Gebruikers. In dat geval moet hij Bart Loosvelt vrijwaren van iedere
			schadeclaim die volgt.
		</p>
		<p><strong>3. Links naar andere websites</strong></p>
		<p>
			De inhoud van onze website kan een link, hyperlink of framed link naar
			vreemde websites of andere vormen van elektronische portalen bevatten. Een
			link betekent niet automatisch dat er een band tussen ons en de vreemde
			website bestaat, noch dat wij (impliciet) akkoord gaan met de inhoud van
			deze websites.
		</p>
		<p>
			Wij houden geen controle op deze vreemde websites en zijn niet
			verantwoordelijk voor de veilige en correcte werking van de link en de
			uiteindelijke bestemming. Zodra men op de link klikt verlaat men onze
			website en kan men ons niet meer aansprakelijk stellen voor enige schade.
		</p>
		<p>
			Het is mogelijk dat vreemde websites niet dezelfde garanties bieden als
			wij. Daarom raden wij aan om aandachtig de Algemene Voorwaarden en de
			Privacy Statement van deze websites door te nemen.
		</p>
		<p><strong>4. Intellectuele eigendom</strong></p>
		<p>
			Creativiteit verdient bescherming, zo ook onze website en haar inhoud. De
			bescherming wordt voorzien door intellectuele eigendomsrechten en komt toe
			aan alle rechthebbende partijen, zijnde Bart Loosvelt en derde partijen.
			Onder inhoud verstaat men de heel ruime categorie van foto’s, video,
			audio, tekst, ideeën, notities, tekeningen, artikels, et cetera. Al deze
			inhoud wordt beschermd door het auteursrecht, softwarerecht,
			databankrecht, tekeningen- en modellenrecht en andere toepasselijke
			(intellectuele) eigendomsrechten. Het technische karakter van onze website
			zelf is beschermd door het auteursrecht, het softwarerecht en
			databankenrecht.&nbsp; Iedere handelsnaam die wij gebruiken op onze
			websites wordt eveneens beschermd door het toepasselijke handelsnamenrecht
			of merkenrecht.
		</p>
		<p>
			Iedere Gebruiker krijgt een beperkt recht van toegang, gebruik en weergave
			van onze websites en haar inhoud. Dit toegekende recht is niet-exclusief,
			niet-overdraagbaar en kan enkel binnen een persoonlijk, niet-commercieel
			kader worden gebruikt. Wij vragen onze Gebruikers dan ook om geen gebruik
			te maken van en/of wijzigingen aan te brengen in de door deze rechten
			beschermde zaken, zonder de toestemming van de rechthebbende. Bart
			Loosvelt hecht veel belang aan haar intellectuele eigendomsrechten en
			heeft hiervoor alle mogelijke maatregelen getroffen om de bescherming te
			garanderen. Iedere inbreuk op de bestaande intellectuele eigendomsrechten
			wordt vervolgd.
		</p>
		<p><strong>5. Verwerking persoonsgegevens</strong></p>
		<p>
			De door jou opgegeven informatie is noodzakelijk voor het verwerken en
			voltooien van de bestellingen, en het opstellen van de rekeningen en
			garantiecontracten. Voor elke bestelling is een minimum aan gegevens
			vereist. Verdere gegevens kunnen gevraagd worden in functie van het
			personaliseren van de bestelling. Als de minimumgegevens ontbreken wordt
			de bestelling onvermijdelijk geannuleerd. De persoonsgegevens van de Koper
			zullen uitsluitend verwerkt worden in overeenstemming met de Privacy
			Statement te consulteren via onze website.
		</p>
		<p>
			<strong>6. Algemene bepalingen omtrent de Algemene Voorwaarden.</strong>
		</p>
		<p>
			Wij behouden de vrijheid om onze website en de daarbij horende diensten te
			allen tijde te wijzigen, uit te breiden, te beperken of stop te zetten.
			Dit kan zonder voorafgaande kennisgeving van de Gebruiker en geeft evenmin
			aanleiding tot enige vorm van schadevergoeding.
		</p>
		<p>
			Deze Algemene Voorwaarden (inclusief de Verkoopsvoorwaarden) worden
			exclusief beheerst en geïnterpreteerd in overeenstemming met de Belgische
			Wetgeving. Alle geschillen die verband houden met of voortvloeien uit
			aanbiedingen van Bart Loosvelt, of overeenkomsten die met haar gesloten
			zijn, worden voorgelegd aan de bevoegde rechtbank uit het gerechtelijk
			arrondissement Kortrijk.
		</p>
		<p>
			Indien de werking of geldigheid van één of meerdere van bovenstaande
			bepalingen van deze Algemene Voorwaarden in het gedrang komen, zal dit
			geen gevolg hebben op de geldigheid van de overige bepalingen van deze
			overeenkomst. In dergelijk geval hebben wij het recht om de betrokken
			bepaling te wijzigen in een geldige bepaling van gelijkaardige strekking.
		</p>
		<div class="center">
			<button class="policyClose bottom" (click)="hidePolicy('disclaimer')">
				Sluiten
			</button>
		</div>
	</div>
	<div id="privacybeleid">
		<div class="center">
			<button class="policyClose top" (click)="hidePolicy('privacybeleid')">
				Sluiten
			</button>
		</div>
		<p class="bigger"><b>PRIVACYBELEID</b></p>
		<p><strong>Waarom deze privacyverklaring?</strong></p>
		<p>
			Bart Loosvelt hecht grote waarde aan de bescherming van uw privacy en
			persoonsgegevens. Wij gebruiken uw persoonsgegevens uitsluitend in
			overeenstemming met de Privacywet en andere relevante vigerende wettelijke
			voorschriften. Iedere verwijzing in deze Privacyverklaring naar de
			Privacywet betekent een verwijzing naar de Wet van 8 december 1992 tot
			bescherming van de persoonlijke levenssfeer ten opzichte van de verwerking
			van persoonsgegevens. Iedere verwijzing naar de Verordening is een
			verwijzing naar de Verordening van 27 april 2016 betreffende de
			bescherming van natuurlijke personen in verband met de verwerking van
			persoonsgegevens en betreffende het vrije verkeer van die gegevens.
		</p>
		<p>
			Met deze Privacyverklaring wil Bart Loosvelt u wijzen op eventuele
			verwerkingshandelingen ten aanzien van deze gegevens en op uw rechten.
			Door gebruik te maken van ons platform/onze website/onze applicatie
			verleent u expliciet uw toestemming met mogelijke verwerkingshandelingen
			door Bart Loosvelt.
		</p>
		<p>
			Het is mogelijk dat deze Privacyverklaring in de toekomst onderhevig is
			aan aanpassingen en wijzigingen. Het is aan u om op regelmatige basis dit
			document te raadplegen. Iedere substantiële wijziging zal steeds duidelijk
			gecommuniceerd worden op het platform van Bart Loosvelt.
		</p>
		<p><strong>Wie verwerkt de persoonsgegevens?</strong></p>
		<p>De website www.advocaatloosvelt.be is een initiatief van:</p>
		<p>
			Bart Loosvelt (Hierna: “<strong>Bart Loosvelt</strong>”)<br />
			Zwedenlaan 9<br />
			8550 Zwevegem<br />
			België
		</p>
		<p>
			Btw-nummer: BE 0818 388 109<br />
			E-mail:
			<a href="mailto:info@advocaatloosvelt.be">info&#64;advocaatloosvelt.be</a
			><br />
			Telefoon: 0486 86 17 81
		</p>
		<p><strong>Welke persoonsgegevens worden verwerkt?</strong></p>
		<p>
			Bart Loosvelt verbindt zich ertoe enkel de gegevens te verwerken die ter
			zake dienend zijn en noodzakelijk zijn voor de doeleinden waarvoor zij
			verzameld werden. Volgende categorieën van persoonsgegevens kunnen
			verwerkt worden door Bart Loosvelt:
		</p>
		<ol>
			<li>Identificatiegegevens</li>
			<li>Vrijetijdsbesteding en interessen</li>
		</ol>
		<p>
			<strong
				>Voor welke doeleinden worden mijn persoonsgegevens gebruikt?</strong
			>
		</p>
		<p>
			Bart Loosvelt verzamelt persoonsgegevens om u een veilige, optimale en
			persoonlijke gebruikerservaring te bieden. De verzameling van
			persoonsgegevens wordt uitgebreider naarmate u intensiever gebruik maakt
			van de website en onze online dienstverlening.
		</p>
		<p>
			<strong>&nbsp;</strong>Gegevensverwerking is essentieel voor de werking
			van de website en de daarbij horende diensten. De verwerking gebeurt
			uitsluitend voor volgende welbepaalde doeleinden:
		</p>
		<ol>
			<li>
				Het aanbieden en verbeteren van een algemene en gepersonaliseerde
				dienstverlening; inclusief facturatiedoeleinden, aanbod van informatie,
				nieuwsbrieven en aanbiedingen die nuttig en/of noodzakelijk zijn voor u,
				de verkrijging en verwerking van gebruikersbeoordelingen en het verlenen
				van ondersteuning.
			</li>
			<li>Marketing doeleinden</li>
		</ol>
		<p>
			Bij een bezoek aan het de website van Bart Loosvelt worden er enkele
			gegevens ingezameld voor statistische doeleinden. Dergelijke gegevens zijn
			noodzakelijk om het gebruik van onze website te optimaliseren. Deze
			gegevens zijn: IP-adres, vermoedelijke plaats van consultatie, uur en dag
			van consultatie, welke pagina’s er werden bezocht. Wanneer u de website
			van Bart Loosvelt bezoekt verklaart u zich akkoord met deze
			gegevensinzameling bestemd voor statische doeleinden zoals hierboven
			omschreven.
		</p>
		<p>
			De Gebruiker verschaft steeds zelf de persoonsgegevens aan Bart Loosvelt
			en kan op die manier een zekere controle uitoefenen. Indien bepaalde
			gegevens onvolledig of ogenschijnlijk incorrect zijn, behoudt Bart
			Loosvelt zich het recht voor bepaalde verwachte handelingen tijdelijk of
			permanent uit te stellen.
		</p>
		<p>
			De persoonsgegevens worden enkel verwerkt voor intern gebruik binnen Bart
			Loosvelt.<br />
			We kunnen u dan ook geruststellen dat persoonsgegevens niet verkocht,
			doorgegeven of meegedeeld worden aan derde partijen die aan ons verbonden
			zijn. Bart Loosvelt heeft alle mogelijke juridische en technische
			voorzorgen genomen om ongeoorloofde toegang en gebruik te vermijden.
		</p>
		<p><strong>Wij gebruiken ook cookies!</strong></p>
		<p>
			Tijdens een bezoek aan onze website kunnen ‘cookies’ op uw harde schijf
			geplaatst worden om de website beter af te stemmen op de behoeften van de
			terugkerende bezoeker. Niet-functionele cookies helpen ons om uw bezoek
			aan het platform te optimaliseren en om technische keuzes te herinneren.
		</p>
		<p>
			Voor een verder begrip van de wijze waarop wij cookies gebruiken om uw
			persoonsgegevens te verzamelen en te verwerken, verwijzen wij u graag door
			naar onze Cookieverklaring.
		</p>
		<p>
			Als u het platform/de website van Bart Loosvelt wil consulteren, is het
			aan te raden dat u cookies ingeschakeld hebt. Hoe u cookies daarentegen
			kan uitschakelen, staat eveneens te lezen in onze Cookieverklaring.
		</p>
		<p><strong>Wat zijn mijn rechten?</strong></p>
		<p>
			<strong
				>Garantie van een rechtmatige en veilige verwerking van de
				persoonsgegevens</strong
			>
		</p>
		<p>
			Bart Loosvelt verwerkt uw persoonsgegevens steeds eerlijk en rechtmatig.
			Dit houdt volgende garanties in:
		</p>
		<ol>
			<li>
				Persoonsgegevens worden enkel conform de omschreven en gerechtvaardigde
				doeleinden van deze Privacyverklaring verwerkt.
			</li>
			<li>
				Persoonsgegevens worden enkel verwerkt voor zover dit toereikend, ter
				zake dienend en niet overmatig is.
			</li>
			<li>
				Persoonsgegevens worden maar bewaard zolang dit noodzakelijk is voor het
				verwezelijken van de omschreven en gerechtvaardigde doeleinden in deze
				Privacyverklaring.
			</li>
		</ol>
		<p>
			De nodige technische en beveiligingsmaatregelen werden genomen om de
			risico’s op onrechtmatige toegang tot of verwerking van de
			persoonsgegevens tot een minimum te reduceren. Bij inbraak in haar
			informaticasystemen zal Bart Loosvelt onmiddellijk alle mogelijke
			maatregelen nemen om de schade tot een minimum te beperken.
		</p>
		<p>
			<strong
				>Recht op inzage/rectificatie/wissen van uw persoonsgegevens</strong
			>
		</p>
		<p>
			Bij bewijs van uw identiteit als Gebruiker, beschikt u over een recht om
			van Bart Loosvelt uitsluitsel te krijgen over het al dan niet verwerken
			van uw persoonsgegevens. Wanneer Bart Loosvelt uw gegevens verwerkt, heeft
			u bovendien het recht om inzage te verkrijgen in de verzamelde
			persoonsgegevens. Indien u wenst uw recht op inzage te gebruiken, zal Bart
			Loosvelt hieraan gevolg geven binnen één (1) maand na het ontvangen van de
			aanvraag. De aanvraag gebeurt via aangetekende zending of via het
			versturen van een e-mail naar
			<a href="mailto:info@advocaatloosvelt.be">info&#64;advocaatloosvelt.be</a>
		</p>
		<p>
			Onnauwkeurige of onvolledige persoonsgegevens kunnen steeds verbeterd
			worden. Het is aan de Gebruiker om in de eerste plaats zelf
			onnauwkeurigheden en onvolledigheden aan te passen. U kan uw recht op
			verbetering uitoefenen door een aanvullende verklaring te verstrekken aan
			Bart Loosvelt. Bart Loosvelt zal hieraan gevolg geven binnen één (1) maand
			na het ontvangen van de aanvullende verklaring.
		</p>
		<p>
			U heeft bovendien het recht om zonder onredelijke vertraging uw
			persoonsgegevens door ons te laten wissen. U kan slechts beroep doen op
			dit recht om vergeten te worden in de hiernavolgende gevallen:
		</p>
		<ol>
			<li>
				Wanneer de persoonsgegevens niet langer nodig zijn voor de doeleinden
				waarvoor zij oorspronkelijk werden verzameld;
			</li>
			<li>
				Wanneer de persoonsgegevens verzameld werden op basis van verkregen
				toestemming en geen andere rechtsgrond bestaat voor de verwerking;
			</li>
			<li>
				Wanneer bezwaar wordt gemaakt tegen de verwerking en geen prevalerende
				dwingende gerechtvaardigde gronden voor de verwerking bestaan;
			</li>
			<li>Wanneer de persoonsgegevens onrechtmatig werden verwerkt;</li>
			<li>
				Wanneer de persoonsgegevens gewist moeten worden overeenkomstig een
				wettelijke verplichting.
			</li>
		</ol>
		<p>
			Bart Loosvelt beoordeelt de aanwezigheid van een van de voornoemde
			gevallen.
		</p>
		<p>
			<strong
				>Recht op beperking van/bezwaar tegen de verwerking van uw
				persoonsgegevens</strong
			>
		</p>
		<p>
			Gebruiker heeft het recht om een beperking van de verwerking van uw
			persoonsgegevens te verkrijgen:
		</p>
		<ol>
			<li>
				Gedurende de periode die Bart Loosvelt nodig heeft om de juistheid van
				de persoonsgegevens te controleren, in geval van betwisting;
			</li>
			<li>
				Wanneer de gegevensverwerking onrechtmatig is en Gebruiker verzoekt tot
				een beperking van de verwerking in plaats van het wissen van de
				persoonsgegevens;
			</li>
			<li>
				Wanneer Bart Loosvelt de persoonsgegevens van de Gebruiker niet meer
				nodig heeft voor de verwerkingsdoeleinden en Gebruiker de
				persoonsgegevens nodig heeft inzake een rechtsvordering;
			</li>
			<li>
				Gedurende de periode die Bart Loosvelt nodig heeft om de aanwezigheid
				van de gronden voor het wissen van persoonsgegevens te beoordelen.
			</li>
		</ol>
		<p>
			Gebruiker heeft bovendien te allen tijde het recht om bezwaar te maken
			tegen de verwerking van zijn persoonsgegevens. Bart Loosvelt staakt hierna
			de verwerking van uw persoonsgegevens, tenzij Bart Loosvelt dwingende
			gerechtvaardigde gronden voor de verwerking van uw persoonsgegevens kan
			aanvoeren die zwaarder wegen dan het recht van de Gebruiker op bezwaar.
		</p>
		<p>
			Indien Gebruiker wenst om deze rechten uit te oefenen, zal Bart Loosvelt
			hieraan gevolg geven binnen één&nbsp; (1) maand na het ontvangen van de
			aanvraag. De aanvraag gebeurt via aangetekende zending of via een e-mail
			naar
			<a href="mailto:info@advocaatloosvelt.be">info&#64;advocaatloosvelt.be</a>
		</p>
		<p><strong>Recht op gegevensoverdraagbaarheid</strong></p>
		<p>
			Gebruiker heeft het recht om de aan Bart Loosvelt verstrekte
			persoonsgegevens in een gestructureerde, gangbare en machine leesbare vorm
			te verkrijgen. Daarnaast heeft Gebruiker het recht om deze
			persoonsgegevens over te dragen aan een andere
			verwerkingsverantwoordelijke wanneer de verwerking van de persoonsgegevens
			uitsluitend rust op de verkregen toestemming van de Gebruiker.
		</p>
		<p>
			Indien Gebruiker wenst om dit recht uit te oefenen, zal Bart Loosvelt
			hieraan gevolg geven binnen één (1) maand na het ontvangen van de
			aanvraag. De aanvraag gebeurt via aangetekende zending of via een e-mail
			naar
			<a href="mailto:info@advocaatloosvelt.be">info&#64;advocaatloosvelt.be</a
			>.
		</p>
		<p>
			<strong
				>Recht op het intrekken van mijn toestemming/recht om klacht in te
				dienen</strong
			>
		</p>
		<p>
			Gebruiker heeft te allen tijde het recht om zijn toestemming in te
			trekken. Het intrekken van de toestemming laat de rechtmatigheid van de
			verwerking op basis van de toestemming vóór de intrekking daarvan,
			onverlet. Daarnaast heeft Gebruiker het recht om klacht in te dienen
			betreffende de verwerking van zijn persoonsgegevens door Bart Loosvelt bij
			de Belgische Commissie voor de Bescherming van de Persoonlijke
			Levenssfeer.
		</p>
		<p>
			Indien Gebruiker wenst dit recht uit te oefenen, zal Bart Loosvelt hieraan
			gevolg geven binnen één (1) maand na het ontvangen van de aanvraag. De
			aanvraag gebeurt via aangetekende zending of via e-mail naar
			<a href="mailto:info@advocaatloosvelt.be">info&#64;advocaatloosvelt.be</a>
		</p>
		<div class="center">
			<button class="policyClose bottom" (click)="hidePolicy('privacybeleid')">
				Sluiten
			</button>
		</div>
	</div>
	<div id="cookieverklaring">
		<div class="center">
			<button class="policyClose top" (click)="hidePolicy('cookieverklaring')">
				Sluiten
			</button>
		</div>
		<p class="bigger"><b>COOKIEVERKLARING</b></p>
		<p>
			Cookies zijn kleine data- of tekstbestanden die door websites en
			applicaties op uw lokale computer worden geplaatst. Dergelijke cookies
			kunnen verschillende doeleinden hebben: er zijn technische cookies
			(bijvoorbeeld voor het bijhouden van taalinstellingen), sessiecookies
			(tijdelijke cookies die verlopen na één sessie) en tracking cookies
			(cookies die uw gedrag op het internet volgen en bijhouden, om u op die
			manier een meer optimale gebruikservaring te kunnen aanbieden).
		</p>
		<p>
			De Belgische Wet betreffende de elektronische communicatie van 13 juni
			2005 bevat enkele bepalingen rond cookies en het gebruik ervan op
			websites. De wet is een omzetting van de Europese e-Privacyrichtlijn, wat
			betekent dat de cookiewetgeving verschillend kan geïmplementeerd worden in
			andere Europese staten.
		</p>
		<p>
			Bart Loosvelt is gevestigd in België en volgt bijgevolg de Belgische
			wetgeving inzake cookies.
		</p>
		<p><strong>Doel en nut van cookies</strong></p>
		<p>
			Bart Loosvelt wil elke bezoeker van het platform/de website zo goed
			mogelijk informeren over zijn rechten onder de Belgische wetgeving inzake
			cookies, en over welke cookies Bart Loosvelt gebruikt. Door het
			platform/de website te gebruiken gaat de bezoeker akkoord met het gebruik
			van cookies. Cookies helpen Bart Loosvelt om uw bezoek aan het platform/de
			website te optimaliseren, om technische keuzes te herinneren (bijvoorbeeld
			een taalkeuze, een nieuwsbrief, et cetera) en om u meer relevante diensten
			en aanbiedingen te tonen.
		</p>
		<p>
			Als u het platform/de website van Bart Loosvelt wil consulteren, is het
			aan te raden dat de technische instellingen voor cookies ingeschakeld
			werden. Zonder ingeschakelde cookies kan Bart Loosvelt geen probleemloos
			bezoek op het platform/de website garanderen. Indien u de cookies liever
			niet wenst, bent u als bezoeker vrij om de cookies uit te schakelen.
		</p>
		<p>
			Wij gebruiken cookies om uw bezoek aan ons platform/onze website te
			verbeteren. De cookies die wij gebruiken zijn veilig. De informatie die we
			verzamelen met behulp van cookies helpt ons om eventuele fouten te
			identificeren of om u specifieke diensten te laten zien waarvan wij denken
			dat ze voor u van belang kunnen zijn.
		</p>
		<p><strong>Soorten cookies gebruikt door Bart Loosvelt</strong></p>
		<p>We onderscheiden volgende types cookies, naargelang hun doeleinden:</p>
		<p><strong>Essentiële/ Strikt noodzakelijke cookies:</strong></p>
		<p>
			Deze cookies zijn nodig om het platform/de website te laten functioneren
			en kunnen niet worden uitgeschakeld in onze systemen. Ze worden meestal
			alleen ingesteld als reactie op acties die door u zijn gesteld, zoals het
			instellen van uw privacyvoorkeuren, inloggen of het invullen van
			formulieren. Ze zijn noodzakelijk voor een goede communicatie en ze
			vergemakkelijken het navigeren (bijvoorbeeld naar een vorige pagina
			terugkeren, etc.).
		</p>
		<p><strong>Niet-essentiële cookies:</strong></p>
		<p>
			Deze cookies zijn op zich niet noodzakelijk om het platform/de website te
			laten functioneren, maar ze helpen ons wel een verbeterde en
			gepersonaliseerde website aan te bieden.
		</p>
		<p><strong>Functionele cookies:</strong></p>
		<p>
			Deze cookies stellen het platform/de website in staat om verbeterde
			functionaliteit en personalisatie te bieden. Ze kunnen worden ingesteld
			door ons of door externe providers wiens diensten we hebben toegevoegd aan
			onze pagina’s.
		</p>
		<p><strong>Analytische cookies:</strong></p>
		<p>
			Met deze cookies kunnen we bezoeken en traffic bijhouden, zodat we de
			prestaties van ons platform/onze website kunnen meten en verbeteren. Ze
			helpen ons te weten welke pagina’s het meest en het minst populair zijn en
			hoe bezoekers zich door het platform/de website verplaatsen.
		</p>
		<p><strong>Targeting / advertising cookies:</strong></p>
		<p>
			Deze cookies kunnen door onze advertentiepartners via ons platform/onze
			website worden ingesteld.<br />
			Ze kunnen door die bedrijven worden gebruikt om een profiel van uw
			interesses samen te stellen en u relevante advertenties op andere sites te
			laten zien.
		</p>
		<p>
			Wij gebruiken enerzijds onze eigen cookies en anderzijds cookies van
			zorgvuldig geselecteerde partners met wie we samenwerken en die onze
			diensten op hun website adverteren.
		</p>
		<p>Wij gebruiken deze cookies op onze site</p>
		<p>&nbsp;</p>
		<table style="text-align: start;">
			<tbody>
				<tr>
					<td>Naam cookie</td>
					<td>Type cookie</td>
					<td>Bewaarduur</td>
				</tr>
				<tr>
					<td>Google analytics</td>
					<td>Logging van bezoekers</td>
					<td>2 jaar</td>
				</tr>
			</tbody>
		</table>
		<p>&nbsp;</p>
		<p>
			Neem kennis van onze Privacyverklaring voor meer informatie omtrent de
			verwerking van persoonsgegevens door Bart Loosvelt.
		</p>
		<p><strong>Beheer van de cookies</strong></p>
		<p>
			Zorg ervoor dat cookies zijn ingeschakeld in uw browser. Om cookies in te
			schakelen moeten de volgende handelingen uitgevoerd worden:
		</p>
		<p><strong>Bij browser – Microsoft Internet Explorer</strong></p>
		<ul>
			<li>
				In Internet Explorer, klik op ‘Internetopties’ in het menu ‘Extra’.
			</li>
			<li>
				Op het tabblad ‘Privacy’, verplaats de instellingen- schuifknop naar
				‘laag’ of ‘accepteer alle cookies’ (instelling boven ‘medium’ schakelt
				cookies uit).
			</li>
			<li>Klik op ‘OK’.</li>
		</ul>
		<p><strong>Bij browser – Mozilla Firefox</strong></p>
		<ul>
			<li>
				Klik op ‘Firefox’ in de linkerbovenhoek van uw browser en klik
				vervolgens op ‘Opties’.
			</li>
			<li>
				Op het tabblad ‘Privacy’, zorg ervoor dat de ‘Websites laten weten dat
				ik niet gevolgd wil worden’ niet is aangevinkt.
			</li>
			<li>Klik op ‘OK’.</li>
		</ul>
		<p><strong>Bij browser – Google Chrome</strong></p>
		<ul>
			<li>
				Klik op de drie puntjes naast de browserbalk bovenaan in uw
				browservenster en kies ‘Opties’.
			</li>
			<li>
				Zoek het gedeelte ‘Privacy and security’ en klik op ‘content settings’.
			</li>
			<li>Klik op de optie ‘cookies’.</li>
			<li>Selecteer nu ‘Allow sites to save and read cookie data’.</li>
		</ul>
		<p><strong>Bij browser – Safari</strong></p>
		<ul>
			<li>
				Kies ‘Voorkeuren’ in het taakmenu. (Het taakmenu bevindt zich
				rechtsboven in het Safari-venster en ziet eruit als een tandwiel of klik
				op ‘Safari’ in het uitgebreide taakmenu.)
			</li>
			<li>
				Klik op het Privacy tabblad. Zoek de sectie genaamd ‘Cookies en andere
				websitegegevens’
			</li>
			<li>Duid aan dat u cookies aanvaardt.</li>
		</ul>
		<p>
			Als u het platform/de website van Bart Loosvelt wil consulteren, is het
			aan te raden dat u cookies ingeschakeld hebt. Echter, als u dit liever
			niet wenst, bent u als bezoeker vrij om de cookies uit te schakelen via uw
			browserinstellingen. Dit kan via volgende manieren:
		</p>
		<p><strong>Bij browser – Microsoft Internet Explorer</strong></p>
		<ul>
			<li>
				Selecteer in Internet Explorer de knop Extra en selecteer
				Internetopties.
			</li>
			<li>
				Selecteer het tabblad Privacy en verplaats onder Instellingen de
				schuifregelaar naar boven om alle cookies te blokkeren. Klik op OK.
			</li>
		</ul>
		<p><strong>Bij browser – Mozilla Firefox</strong></p>
		<ul>
			<li>Klik op de menuknop en kies ‘Voorkeuren’.</li>
			<li>
				Selecteer het paneel ‘Privacy &amp; Beveiliging’ en ga naar de sectie
				‘Geschiedenis’.
			</li>
			<li>
				Stel naast ‘Firefox zal’ in op ‘Aangepaste instellingen gebruiken voor
				geschiedenis’.
			</li>
			<li>
				Stel&nbsp;‘Cookies van derden accepteren’&nbsp;in op&nbsp;‘Nooit’.
			</li>
			<li>
				Sluit de pagina&nbsp;‘about: preferences’. Wijzigingen die u hebt
				aangebracht, worden automatisch opgeslagen.
			</li>
		</ul>
		<p><strong>Bij browser – Google Chrome</strong></p>
		<ul>
			<li>Selecteer ‘Meer Instellingen’ in de browserwerkbalk.</li>
			<li>Selecteer onderaan de pagina de optie ‘Geavanceerd’.</li>
			<li>
				Selecteer bij ‘Privacy en beveiliging’ de optie ‘Instellingen voor
				content’.
			</li>
			<li>Selecteer ‘Cookies’.</li>
			<li>
				Schakel ‘Sites toestaan cookiegegevens op te slaan en te lezen’ uit.
			</li>
		</ul>
		<p><strong>Bij browser – Safari</strong></p>
		<ul>
			<li>
				Kies ‘Voorkeuren’ in het taakmenu. (Het taakmenu bevindt zich
				rechtsboven in het Safari-venster en ziet eruit als een tandwiel of klik
				op ‘Safari’ in het uitgebreide taakmenu.)
			</li>
			<li>
				Klik op het Privacy tabblad. Zoek de sectie genaamd ‘Cookies en andere
				websitegegevens’
			</li>
			<li>Duid aan dat u cookies niet aanvaardt.</li>
		</ul>
		<p>Of raadpleeg hiervoor de help-functie van uw internetbrowser.</p>
		<p><strong>Rechten van de bezoekers</strong></p>
		<p>
			Aangezien cookies een verwerking van persoonsgegevens kunnen uitmaken,
			heeft u als betrokkene recht op de rechtmatige en veilige verwerking van
			de persoonsgegevens. Als betrokkene kan u volgende rechten uitoefenen:
		</p>
		<ul>
			<li>
				Recht op verzet: Indien er sprake is van een zwaarwegende en
				gerechtvaardigde redenen kan men zich verzetten tegen de verwerking van
				persoonsgegevens.
			</li>
			<li>
				Recht op toegang: Iedere betrokkene die zijn identiteit bewijst,
				beschikt over een recht op toegang tot de informatie rond het al dan
				niet bestaan van verwerkingen van zijn persoonsgegevens, net als de
				doeleinden van deze verwerking, de categorieën van gegevens waarop deze
				verwerkingen betrekking hebben en de categorieën van ontvangers aan wie
				de gegevens worden verstrekt.
			</li>
			<li>
				Recht op verbetering: Onnauwkeurige of onvolledige persoonsgegevens
				kunnen op verzoek van de betrokkene steeds verbeterd of zelfs uitgewist
				worden.
			</li>
		</ul>
		<p>
			De uitoefening van deze rechten gebeurt conform de modaliteiten zoals
			bepaald in onze Privacyverklaring. Meer informatie over de rechten van
			bezoekers vindt u ook in de Privacyverklaring. Mocht u na het lezen van
			deze Cookieverklaring toch nog vragen of opmerkingen rond cookies hebben,
			kan u steeds contact opnemen via
			<a href="mailto:info@advocaatloosvelt.be">info&#64;advocaatloosvelt.be</a
			>.
		</p>
		<div class="center">
			<button
				class="policyClose bottom"
				(click)="hidePolicy('cookieverklaring')"
			>
				Sluiten
			</button>
		</div>
	</div>
</div>
