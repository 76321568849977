import { Component, OnInit } from '@angular/core';
import { text } from '../../../assets/text.js';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
	public introTitle: string;
	public introText: string;

	//------------------------------------------------------------------- CONSTRUCTOR
	constructor() {

	}


	//------------------------------------------------------------------- INIT
	async ngOnInit() {
		this.introTitle = text.home.title;
		this.introText = text.home.text;
	}
}
