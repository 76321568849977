import { Environment } from '@interfaces/environment.interface';
import pckgfile from 'package.json';

export const environment: Environment = {
	version: pckgfile.version,

	app_id: 'template-dev',   // intended for backup & analytics service

	development: true,
	production: false,
	serviceworker: false,

	toasts: {
		debug: false,
		appVersion: true
	},

	email: "info@advocaatloosvelt.be",

	// TODO: might use a fetch some pictures ??
	dgtcloud: {
		url: 'https://api.dgtstaging.cloud/',
		token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c',
	},
};
