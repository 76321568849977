<label class="custom-radiobutton {{ disabled ? 'disabled' : '' }}">
   <input type="radio"
      [name]="name"
      [checked]="checked"
      (click)="clickRadiobutton()"
      [value]="value"
   >
   <span class="text">
   <span class="icon"></span>
      <ng-content></ng-content>
   </span>
</label>