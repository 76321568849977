import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '@environments/environment';

// Services
import { NavigationService } from '@services/navigation.service';

// HOD Packages
import { HodModule } from '@houseofdigital/angular-lib';

// Components
import { ModalComponent } from '@components/modal/modal.component';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { NavigationComponent } from '@components/navigation/navigation.component';
import { VideoPlayerComponent } from '@components/videoplayer/videoplayer.component';
import { CheckboxComponent } from '@components/forms/checkbox/checkbox.component';
import { RadiobuttonComponent } from '@components/forms/radiobutton/radiobutton.component';
import { DropdownComponent } from '@components/forms/dropdown/dropdown.component';

// Views
import { AppComponent } from './app.component';
import { HomeComponent } from '@views/home/home.component';
import { FormsComponent } from '@views/debug/forms/forms.component';   // debug only
import { Spinner2Component } from '@components/spinner2/spinner2.component';
import { BannerComponent } from './components/banner/banner.component';
import { TextCarouselComponent } from './components/text-carousel/text-carousel.component';
import { ContactComponent } from './components/contact/contact.component';
import { HeaderComponent } from './components/header/header.component';
import { FormComponent } from './components/forms/form/form.component';
import { CookieComponent } from './components/cookie/cookie.component';
import { CaptchaComponent } from './components/captcha/captcha.component';


@NgModule({
  declarations: [
    // Components:
    ModalComponent,
    SpinnerComponent,
    NavigationComponent,
    VideoPlayerComponent,
    CheckboxComponent,
    RadiobuttonComponent,
    DropdownComponent,

    // Views:
    AppComponent,
    HomeComponent,
    FormsComponent,
    Spinner2Component,
    BannerComponent,
    TextCarouselComponent,
    ContactComponent,
    HeaderComponent,
    FormComponent,
    CookieComponent,
    CaptchaComponent,
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    
    HodModule.forRoot(environment),
  ],

  providers: [
    // Services
    NavigationService,
  ],

  bootstrap: [AppComponent]
})


export class AppModule { }
