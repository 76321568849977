import { Component } from "@angular/core";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  public error = false;
  public initialized = false;
  public isSettings = false;

  constructor() {}

  ngOnInit() {
    this.startApplication();
  }

  // -------------------------------------------------------------- DEFAULT START
  startApplication() {
    this.initialized = true;
  }
}
