<div class="videplayer fit-{{ fit }}">
  <video
    [src]="src"
    [download]="download"
    playsinline
    preload="metadata"
    poster="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
    [autoplay]="autoplay ? 'autoplay' : ''"
    [loop]="loop ? 'loop' : ''"
    [muted]="muted ? 'muted' : ''"
    #videotag
  ></video>

  <div class="controls" *ngIf="customcontrols">
    <div class="controls-wrapper">
      <div class="playpause {{ state.playing ? 'pause' : 'play' }}" (click)="togglePlay()"></div>

      <div class="playtime">{{ state.formattedPlayTime.current }} / {{ state.formattedPlayTime.total }}</div>
      <div class="playbar">
        <div class="playbar-content">
          <div class="bars">
            <div class="played" [style.width]="(state.playPercentage*100)+'%'"></div>
            <div class="buffered" [style.width]="(state.bufferPercentage*100)+'%'"></div>
          </div>
          <div class="handle" [style.left]="(state.playPercentage*100)+'%'" [style.transform]="'translate(-'+(state.playPercentage*50)+'%, 0)'"></div>
        </div>
      </div>
    </div>
  </div>

  <pre>{{ state | json }}</pre>

  <app-spinner class="absolute" *ngIf="state.buffering"></app-spinner>
</div>