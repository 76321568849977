import { Component,	ViewEncapsulation, Renderer2, HostListener } from "@angular/core";
import { text } from "../../../assets/text.js";


@Component({
	selector: "app-contact",
	templateUrl: "./contact.component.html",
	styleUrls: ["./contact.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class ContactComponent {
	private textFieldsLeft: string[] = text.contact[0];
	private textFieldsRight: string[] = text.contact[1];
	public textFields: string[] = [];

	constructor(private renderer: Renderer2) {}

	ngOnInit(): void {
		this.updateDisplayedItems(window.innerWidth);
	}

	@HostListener("window:resize", ["$event"])
	onResize(event) {
		this.updateDisplayedItems(event.target.innerWidth);
	}

	updateDisplayedItems(width: number) {
		if (width < 800) {
			this.textFields = this.textFieldsLeft.concat(this.textFieldsRight);
		} else {
			this.textFields = [];
			for (let i = 0; i < this.textFieldsLeft.length; i += 1) {
				this.textFields.push(this.textFieldsLeft[i]);
				this.textFields.push(this.textFieldsRight[i]);
			}
		}
	}

	parser(item: string) {
		if (
			["disclaimer", "privacybeleid", "cookieverklaring"].includes(
				item.toLowerCase()
			)
		) {
			return `<a class="button">${item}</a>`;
		}
		return item;
	}

	showPolicy(policy: string) {
		if (["disclaimer", "privacybeleid", "cookieverklaring"].includes(policy.toLowerCase())) {
			this.renderer.addClass(document.body, `show-${policy.toLowerCase()}`);
		}
	}

	hidePolicy(policy: string) {
		if (["disclaimer", "privacybeleid", "cookieverklaring"].includes(policy.toLowerCase())) {
			this.renderer.removeClass(document.body, `show-${policy.toLowerCase()}`);
		}
	}
}
