import { Component, OnInit } from '@angular/core';
import { CustomKeyboardEvent, CustomKeyboardService, StateService } from '@houseofdigital/angular-lib';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit {
  public formError:boolean = false;

  public dropdownData = [
    { title: 'Select an item', default: true },  // Optionally specify a default item. Not required.
    { id: 1, title: 'Item 1' },
    { id: 2, title: 'Item 2' },
    { id: 3, title: 'Another item' },
    { id: 4, title: 'This is still something else' },
    { id: 5, title: 'Itemke' },
    { id: 6, title: 'Item 6' },
    { id: 7, title: 'Item 7' },
  ];

  public dropdownData2 = [
    'Item 1',
    'Item 2',
    'Another item',
    'This is still something else',
    'Itemke',
    'Item 6',
    'Item 7',
  ];

	public startDate = new Date(2020, 0, 1);

  public selectedDropdownItem;
  public selectedDropdownItem2;

  public input = {
    firstname: '',
    lastname: ''
  }


  constructor(
    public keyboardService: CustomKeyboardService,
    public stateService: StateService,
  ) { }


  ngOnInit(): void {
    console.log('----> TEST focus input: #firstname');
    this.keyboardService.focusInput('firstname');
  }


  lockKeyboard(){
    this.keyboardService.lockKeyboard();
  }
  unlockKeyboard(){
    this.keyboardService.unlockKeyboard();
  }


  submitForm(){
    if(this.isFormValid()){
      alert('All good, submit the data!');
    }
  }


  isFormValid(){
    this.formError = false;

    if(!this.input.firstname){ this.formError = true; }
    if(!this.input.lastname){ this.formError = true; }

    return this.formError === false;
  }




  onUpdateTest(input:CustomKeyboardEvent){
    console.log('onUpdateTest() input', input);
  }

  onEnterKeyTest(input:CustomKeyboardEvent){
    console.log('onEnterKeyTest() input', input);
    alert('enter key!');
  }



  ngOnDestroy(){
    this.keyboardService.unlockKeyboard();
  }

}