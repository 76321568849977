<div id="cookieBackground">
    <div class="cookieContainer">
        <div class="cookieText">
            <p>Door verder te surfen op onze website, ga je akkoord met het gebruik van de cookies.</p>
        </div>
        <div class="cookieButtons">
            <button (click)="acceptCookie()">Aanvaard</button>
            <button (click)="rejectCookie()">Weiger</button>
            <button class="fade" (click)="showCookies()">Cookieverklaring</button>
        </div>
    </div>
</div>
