import { Component, OnInit, ViewChild, ElementRef, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { helpers } from '@houseofdigital/angular-lib';

@Component({
  selector: 'app-videoplayer',
  templateUrl: './videoplayer.component.html',
  styleUrls: ['./videoplayer.component.scss']
})


export class VideoPlayerComponent implements OnInit {
  @Input('src') public src:string|SafeUrl;    // either this
  @Input('download') public download:string | { url: string, version?: string | number };  // or this

  @Input('muted') public muted:boolean = true;   // careful, FALSE might block from autoplaying the video!

  @Input('autoplay') public autoplay:boolean = true;
  @Input('loop') public loop:boolean = false;
  @Input('fit') public fit:string;

  @Input('customcontrols') public customcontrols:boolean = false;

  @Output() public onPlay = new EventEmitter(false);
  @Output() public onEnded = new EventEmitter(false);

  @ViewChild('videotag') private videotag: ElementRef | null = null;

  private videoElem:HTMLVideoElement | null = null;
  public videoCanPlay:boolean = false;

  public state = {
    playing: false,
    ended: false,
    duration: 0,
    currentTime: 0,
    playPercentage: 0,
    formattedPlayTime: { current: '00:00', total: '00:00' },
    buffering: false,
    firstBuffer: true,
    bufferPercentage: 0,
  }

  private lastPlayPos: number;

  private updateInterval;

  private events:{
    canplayHandlerListener?: any,
    endedHandlerListener?: any,
  } = {};

  constructor(
  ) { }

  ngOnInit(){
    if(this.src) console.log('src,',this.src);
    else if(this.download) console.log('download,',this.download);
    else console.warn('VIDEOPLAYER: Error: no src or download provided');
  }

  ngAfterViewInit(){
    if(this.videotag){
      this.videoElem = this.videotag.nativeElement;
      
      if(this.videoElem){
        // TODO: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video -> Pick correct events
        this.events.canplayHandlerListener = this.onVideoCanplayHandler.bind(this);
        this.videoElem.addEventListener('canplay', this.events.canplayHandlerListener);
        
        this.events.endedHandlerListener = this.onVideoEndedHandler.bind(this);
        this.videoElem.addEventListener('ended', this.events.endedHandlerListener);
        
        this.videoElem.load();
        
        this.updateInterval = setInterval(()=>{ this.checkVideoState() }, 50);

        console.log('VIDEOPLAYER: init() videoElem', this.videoElem);
        
      }
    }else{
      console.warn('VIDEOPLAYER: Missing video tag')
    }
  }
  

  onVideoCanplayHandler(e:Event){
    console.log('onVideoCanplayHandler()', this.src || this.download);
    this.videoElem.removeEventListener('canplay', this.events.canplayHandlerListener);
    this.videoCanPlay = true;

    this.videoElem.muted = this.muted;
    this.state.duration = this.videoElem.duration;
    //this.videoElem.currentTime = this.videoElem.duration * Math.random();   // RANDOM POS

    if(this.autoplay || this.state.playing){
      this.playFromStart();
    }else{
      this.stopVideo();
    }
  };


  checkVideoState() {
    this.state.currentTime = this.videoElem.currentTime;
    
    // if not buffering && playTime doesn't advance:
    if (!this.videoElem.paused) {
      let offset = (50 - 30) / 1000;

      if (!this.state.buffering && this.state.currentTime < (this.lastPlayPos + offset)) {
        //console.log("buffering");
        this.state.buffering = true;
      }

      // if was buffering && playTime has advanced => not buffering anymore:
      if (this.state.buffering && this.state.currentTime > (this.lastPlayPos + offset)) {
        this.state.buffering = false;
        this.state.firstBuffer = false;
      }
    }
    this.lastPlayPos = this.state.currentTime;

    if(this.state.duration){
      this.state.bufferPercentage = Math.floor((this.videoElem.buffered.end(0) / this.state.duration)*1000)/1000;
    }

    // update playTime:
    if (this.state.duration) {
      this.state.playPercentage = Math.floor((this.state.currentTime / this.state.duration)*1000)/1000;
      this.state.formattedPlayTime.current = helpers.addLeadingZero(Math.floor(this.state.currentTime / 60)) + ':' + helpers.addLeadingZero(Math.floor(this.state.currentTime % 60));
      this.state.formattedPlayTime.total = helpers.addLeadingZero(Math.floor(this.state.duration / 60)) + ':' + helpers.addLeadingZero(Math.floor(this.state.duration % 60));
    }
  }

  togglePlay(){
    if(this.videoElem.paused) this.videoElem.play();
    else                      this.videoElem.pause();
    this.state.playing = !this.videoElem.paused;
  }


  onVideoEndedHandler(e: Event){
    console.log('onVideoEndedHandler()', this.src || this.download);
    this.onEnded.emit();
  }



  playFromStart(){
    //console.log('VIDEOPLAYER: playFromStart() videoCanPlay? ',this.videoCanPlay, this.videoElem);
    if(this.videoElem){
      if(this.videoCanPlay)  this.videoElem.play();
    }
    this.state.playing = true;
    this.onPlay.emit();
    // TODO: check if acutally playing?
  }

  stopVideo(){
    if(this.videoElem){
      this.videoElem.currentTime = 0;
      this.videoElem.pause();
    }
    this.state.playing = false;
  }



  // listen for changes in @Input active variable:
  /*ngOnChanges(changes:SimpleChanges) {
    if(this.autoplay && !this.playing){
      this.playFromStart();
    }

    if(this.playing && !this.autoplay){
      this.stopVideo();
    }
  }*/


  ngOnDestroy(){
    if(this.videoElem){
      this.videoElem.removeEventListener('canplay', this.events.canplayHandlerListener);
      this.videoElem.removeEventListener('ended', this.events.endedHandlerListener);
      clearInterval(this.updateInterval);
    }
  }
}
