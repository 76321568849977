<div class="formContainer">
	<a class="top80" id="contact_anchor"></a>
	<div class="formContent">
		<div class="formTitle">CONTACTFORMULIER</div>

		<form
			class="formGrid"
			style="grid-area: name"
			[formGroup]="emailForm"
			(ngSubmit)="sendMail()"
		>
			<div class="formInput" style="grid-area: name">
				<label for="name"> Naam </label>
				<input id="name" type="text" formControlName="name" />
			</div>

			<div class="formInput" style="grid-area: telephone">
				<label for="telephone"> Telefoon </label>
				<input id="telephone" type="text" formControlName="telephone" />
                <div *ngIf="emailForm.get('telephone')?.invalid && (emailForm.get('telephone')?.dirty || emailForm.get('telephone')?.touched)">
                    <p class="error-message">Voer een geldig telefoonnummer in.</p>
                </div>
			</div>

			<div class="formInput" style="grid-area: email">
				<label for="email"> Email </label>
				<input id="email" type="text" formControlName="email" [email]="true" />
                <div *ngIf="emailForm.get('email')?.invalid && (emailForm.get('email')?.dirty || emailForm.get('email')?.touched)">
                    <p class="error-message">Voer een geldig emailadres in.</p>
                </div>
			</div>

			<div class="formInput" style="grid-area: message">
				<label for="message"> Bericht </label>
				<textarea id="message" rows="5" formControlName="message"></textarea>
			</div>

            <div class="formSubmit">
                <button class="button" type="submit" [disabled]="emailForm.invalid">VERSTUUR</button>
            </div>
		</form>

	</div>
</div>
