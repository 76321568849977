import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-radiobutton',
  templateUrl: './radiobutton.component.html',
  styleUrls: []
})
export class RadiobuttonComponent {
  @Input() public name: string;
  @Input() public value: string;
  @Input() public checked: boolean = false;
  @Input() public disabled: boolean = false;
  
  @Output() public onSelect = new EventEmitter(false);

  constructor() { }
  
  clickRadiobutton(){
    this.onSelect.emit();
  }
}
