<div class="app-view padded">
    <form>
 
       <h3>Regular input samples (without customKeyboard)</h3>
 
       <input type="text" placeholder="This is placeholder text" value="">
       <input type="text" value="This is a disabled input element" disabled>
       <input type="search" value="Search">
       <input type="text" value="Error sample" class="error">
 
       <br/><br/>
 
       <!-- ---------------------------- DROPDOWN SAMPLE 1 -->
       <p>Version with object data</p>
       <app-dropdown
       property="title"
       (onClick)="selectedDropdownItem = $event"
       [selected]="selectedDropdownItem"
       [data]="dropdownData">
       <!--
       *** Optional params ***
       defaultTitle="This is a custom default title"
       property="name"
       -->
       </app-dropdown>
 
       <!-- ---------------------------- DROPDOWN SAMPLE 2 -->
       <pre>Selected: {{ selectedDropdownItem | json }}</pre>
 
       <br/>
 
       <p>Version with string-array</p>
       <app-dropdown
       (onClick)="selectedDropdownItem2 = $event"
       [selected]="selectedDropdownItem2"
       [data]="dropdownData2">
       </app-dropdown>
 
 
       <!-- ---------------------------- DROPDOWN SAMPLE 1 -->
       <h3>CustomKeyboard samples</h3>
 
       CustomKeyboard: <a class="button small" (click)="lockKeyboard()">Lock</a>&nbsp;
       <a class="button small" (click)="unlockKeyboard()">Unlock</a>
 
       <!-- onUpdate / onEnterKey tester -->
       <label for="eventtester">Test onUpdate / onEnterKey events</label>
       <input id="eventtester"
          type="text"property
          name="eventtester"
          customKeyboard
          (onUpdate)="onUpdateTest($event)"
          (onEnterKey)="onEnterKeyTest($event)">
 
 
       <!-- firstname -->
       <label for="firstname">Voornaam *</label>
       <input id="firstname"
          type="text"
          name="firstname"
          spellcheck="false"
          required
          customKeyboard
          (onUpdate)="this.input.firstname = $event.value"
          (onEnterKey)="keyboardService.focusInput('lastname')"
          [ngClass]="{'error error_shake': formError && !input.firstname }">
 
 
       <!-- lastname -->
       <label for="lastname">Naam *</label>
       <input id="lastname"
             type="text"
             name="lastname"
             spellcheck="false"
             required
             customKeyboard
          (onUpdate)="this.input.lastname = $event.value"
          (onEnterKey)="submitForm()"
          [ngClass]="{'error error_shake': formError && !input.lastname }">
 
             <!-- number layout -->
             <label for="number">Number layout</label>
       <input id="number"
                 type="number"
                 customKeyboard
             >
 
 
       <pre>{{ input | json }}</pre>
 
       <br/>
 
       <!-- custom input elements -->
       <app-checkbox
          name="check-name"
          [disabled]="false"
          [checked]="stateService.get('custom-checkbox')"
          (onSelect)="stateService.toggle('custom-checkbox')"
          >This is a custom checkbox</app-checkbox>
       <br/>
 
       <app-radiobutton [checked]="stateService.get('custom-radiobox')=='radioA'" value="radioA" (onSelect)="stateService.set('custom-radiobox', 'radioA') "name="custom-group">Custom radio option A</app-radiobutton>
       <app-radiobutton [checked]="stateService.get('custom-radiobox')=='radioB'" value="radioB" (onSelect)="stateService.set('custom-radiobox', 'radioB') "name="custom-group">Custom radio option B</app-radiobutton>
       <app-radiobutton [checked]="stateService.get('custom-radiobox')=='radioC'" value="radioC" (onSelect)="stateService.set('custom-radiobox', 'radioC') "name="custom-group">Custom radio option C</app-radiobutton>
 
       <br/><br/>
 
       <!-- date pickers -->
             <!-- using ngModel: requires angular FormsModule -->
       <!--<input type="datetime-local" [value]="startDate" (ngModelChange)="startDate=$event" [ngModel]="startDate | date:'yyyy-MM-ddTHH:mm'">-->
             {{ startDate }}
 
       <!-- default select (dropdown) -->
       <select name="cars" id="cars">
          <option value="volvo">Volvo</option>
          <option value="saab">Saab</option>
          <option value="mercedes">Mercedes</option>
          <option value="audi">Audi</option>
       </select>
 
       <br /><br/>
 
       <div class="errors" *ngIf="formError">
          <p>Please fill in all required fields</p>
       </div>
 
       <a class="button"(click)="submitForm()">Submit</a>
 
    </form>
 </div>