import { Component, Input } from "@angular/core";
import { text } from "../../../assets/text.js";

@Component({
	selector: "app-banner",
	templateUrl: "./banner.component.html",
	styleUrls: ["./banner.component.scss"],
})
export class BannerComponent {
	private bannerString1: string[];
	private bannerString2: string[];
	private bannerString3: string[];
	public string1: string = "";
	public string2: string = "";
	public string3: string = "";

	constructor() {}

	ngOnInit() {
		this.bannerString1 = text.banner;
		this.bannerString2 = text.banner.slice(-4).concat(text.banner.slice(0, 2));
		this.bannerString3 = text.banner.slice(-2).concat(text.banner.slice(0, 4));

		this.string1 = this.bannerString1[0];
		this.string2 = this.bannerString2[0];
		this.string3 = this.bannerString3[0];

		this.changeBanner(4000);
	}

	changeBanner(delay: number) {
		let index = 1;
		setInterval(() => {
			this.string1 = this.bannerString1[index];
			this.string2 = this.bannerString2[index];
			this.string3 = this.bannerString3[index];
			index += 1;
			index === 6 ? (index = 0) : null;
		}, delay);
	}
}
