<div class="modal fadeIn" *ngIf="this.visible">
	<div class="background" (click)="clickBackground()"></div>


	<div class="align-hor align-vert">
		<div class="wrapper scaleUp">
			<!-- header -->
			<div class="modal-header" *ngIf="title || subtitle || showCloseButton">
				<a *ngIf="showCloseButton" class="close" (click)="close()">CLOSE</a>

				<h2 *ngIf="title">{{ title }}</h2>
				<p *ngIf="subtitle">{{ subtitle }}</p>
			</div>

			<!-- body -->
			<div class="modal-body">
				<ng-content></ng-content>
			</div>

			<!-- footer -->
			<div class="modal-footer" *ngIf="cancelLabel || submitLabel">
				<a *ngIf="cancelLabel" class="button invert" (click)="close()">{{ cancelLabel }}</a>
				<a *ngIf="submitLabel" class="button large  scaleUpBig delay-short" (click)="submit()">{{ submitLabel }}</a>
			</div>
		</div>
	</div>

</div>
