import { Component, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { environment } from "@environments/environment";


@Component({
	selector: "app-form",
	templateUrl: "./form.component.html",
	styleUrls: ["./form.component.scss"],
})
export class FormComponent {
	@ViewChild("nameInput") nameInput: any;
	@ViewChild("telephoneInput") telephoneInput: any;
	@ViewChild("emailInput") emailInput: any;
	@ViewChild("messageInput") messageInput: any;
	private email:string =  environment.email

	emailForm: FormGroup = this.formBuilder.group({
		name: "",
		telephone: [
			"",
			[Validators.required, Validators.pattern(/^[0-9\+]{1}[0-9]{8,}$/)],
		],
		email: [
			"",
			[
				Validators.required,
				Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/),
			],
		],
		message: "",
	});

	constructor(private formBuilder: FormBuilder) {}

	sendMail() {
		let name = this.emailForm.value.name;
		let telephone = this.emailForm.value.telephone;
		let email = this.emailForm.value.email;
		let message = this.emailForm.value.message;

		let messageLines = message.split('\n')

		window.location.href = 
			`mailto:${this.email}?` +
			`subject=Contact - ${name}&body=CONTACT:%0D%0A${name}` +
			`%0D%0A${telephone}%0D%0A${email}%0D%0A----------%0D%0A` +
			`%0D%0A${messageLines.join('%0D%0A')}%0D%0A%0D%0A`
		;
	}
}
