import { Component, EventEmitter, Input, Output } from '@angular/core';

/*
> HTML TAG:
<app-modal #myModal
  title="Modal title"
  subtitle="Modal title"
  //[closeOnOutsideClick]="false"		//=> requires rework of background for click
  [showCloseButton]="true"
  cancelLabel="cancel"
  submitLabel="OK"
  (onOpen)="someFuncOpen()"
  (onClose)="someFuncClose()"
  (onSubmit)="someFuncSubmit()"
  [visible]=true    // shows modal immediately
	>

	<div class="somediv">
		Your modal content here
	</div>

</app-modal>

> USAGE IN HTML DIRECTLY:
<button (click)="myModal.open()" class="button">Open</button>

*/


@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
})


export class ModalComponent{
  @Input() public visible: boolean = false;

	@Input() public title: string = '';
	@Input() public subtitle: string = '';
	//@Input() public closeOnOutsideClick: boolean = true;
  @Input() public showCloseButton: boolean = false;
	@Input() public cancelLabel: string = '';
  @Input() public submitLabel: string = '';

  @Output() public onOpen = new EventEmitter(false);
  @Output() public onClose = new EventEmitter(false);
	@Output() public onSubmit = new EventEmitter(false);


	constructor(
	){}


  //------------------------------------- OPEN / CLOSE
  open(...args: any[]) {
    if(this.visible) return;

    this.visible = true;
		this.onOpen.emit(args);
    //window.setTimeout(() => this.modalRoot.nativeElement.focus(), 0);
  }

  close(...args: any[]) {
    if(!this.visible) return;

    this.visible = false;
		this.onClose.emit(args);
  }

  //------------------------------------- SUBMIT
  submit(...args: any[]) {
		this.onSubmit.emit(args);
  }


  clickBackground(){
    this.close();
  }

}
