import { Component, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss']
})
export class CookieComponent {
  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    let cookieBackground = document.getElementById('cookieBackground');
    document.cookie.indexOf('CookieBy=BartLoosvelt') === -1 ? cookieBackground.style.display = 'block' : cookieBackground.style.display = 'none';
  }

  acceptCookie() {
    document.cookie = 'CookieBy=BartLoosvelt; max-age=' + (60 * 60 * 24 * 7);
    document.getElementById('cookieBackground').style.display = 'none';

    if (!document.cookie) {
      //console.log('Cookies niet aanvaard');
    }
  }

  rejectCookie() {
    // console.log('Cookies geweigerd, sommige functies van de website zullen mogelijk niet werken.');
    document.getElementById('cookieBackground').style.display = 'none';
  }

  showCookies() {
    this.renderer.addClass(document.body, `show-cookieverklaring`)
  }
}
